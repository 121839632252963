
import { onMounted, reactive, ref, getCurrentInstance } from 'vue'
import AxiosAdapter from '../request/AxiosAdapter'
import { throwCustomFunction } from '@/commons/ErrorHandle'
import { useRoute } from 'vue-router'
import SelectIdentityType from './SelectIdentityType.vue'
import GetImageConversion from '../commons/ImageConversion'
import { acceptImage } from '@/commons/unit'

export default {
  name: 'UserType',
  props: {
    // institutionId: String,
    userType: Number,
    proxyType: Number
  },
  components: { SelectIdentityType },
  setup(props: any, context: any) {
    const proxy: any = getCurrentInstance()

    const institutionId = ref()
    const identityType = ref(1)
    const proxyIdentityType = ref(1)
    const agentIdentityType = ref(1)
    const identityPhotoUrl: any[] = []
    const acceptImageUrl = ref(acceptImage)
    const selectOptions = reactive({
      userTypeList: [
        {
          code: 1,
          msg: '本人',
          img: require('@/assets/img/upload_user.png'),
          imgChe: require('@/assets/img/upload_user_che.png')
        },
        {
          code: 2,
          msg: '代理人',
          img: require('@/assets/img/agent.png'),
          imgChe: require('@/assets/img/agent_che.png')
        }
      ],
      userTypeOptions: [
        {
          label: '自然人',
          value: 1
        },
        {
          label: '法定代表人',
          value: 2
        }
      ],
      proxyTypeOptions: [
        {
          label: '法律职业者',
          value: 3
        },
        {
          label: '近亲属',
          value: 4
        },
        {
          label: '员工',
          value: 5
        }
      ]
    })
    const route = useRoute()
    const dialogImageUrl = ref('')
    const dialogVisible = ref(false)
    const caseUser: any[] = []
    const identityPhotoBackUrl: any[] = []
    const legalIdentity: any[] = []
    const legalWorkIdentity: any[] = []
    const delegationUrl: any[] = []
    const hirePaperworkUrl: any[] = []
    const agentUser: any[] = []
    const agentUserBack: any[] = []
    const proxyIdentityPhotoBackUrl: any[] = []
    const agentProxy: any[] = []
    const agentTrust: any[] = []
    const agentOther: any[] = []
    const getUrl = process.env.VUE_APP_BASE_API
    const colNum = ref(1)
    // const userValue = ref(1)
    // const proxyValue = ref(1)
    const urlsList = reactive({
      caseUser: caseUser, //本人自然人 当事人身份证明
      identityPhotoBackUrl: identityPhotoBackUrl, // 本人背面
      identityPhotoUrl: identityPhotoUrl,
      legalIdentity: legalIdentity, //本人法人  当事人身份证明
      legalWorkIdentity: legalWorkIdentity, //本人法人 营业执照副本
      hirePaperworkUrl: hirePaperworkUrl, //本人法人 任职书
      delegationUrl: delegationUrl, //本人法人 委托函
      agentUser: agentUser, //代理人 当事人身份证明
      agentUserBack: agentUserBack, // 代理人 代理人身份证背面
      proxyIdentityPhotoBackUrl: proxyIdentityPhotoBackUrl, // 代理人 代理人背面
      agentProxy: agentProxy, // 代理人 代理人身份证明
      agentTrust: agentTrust, // 代理人 授权委托书
      agentOther: agentOther // 代理人 其他
    })

    onMounted(() => {
      if (route.query.institutionId) {
        institutionId.value = route.query.institutionId
      }
    })

    const changeIdentityType = (val: any) => {
      identityType.value = val
      // 当事人
      urlsList.caseUser.length = 0
      urlsList.legalIdentity.length = 0
      urlsList.identityPhotoBackUrl.length = 0
    }
    const changeAgentIdentityType = (val: any) => {
      identityType.value = val
      //代理人
      urlsList.agentUser.length = 0
      urlsList.agentUserBack.length = 0
    }
    const changeProxyIdentityType = (val: any) => { 
      agentIdentityType.value = val
      urlsList.agentProxy.length = 0
      urlsList.proxyIdentityPhotoBackUrl.length = 0
    }

    // const successUpload = (type: any, res?: any) => {
    // const url = res && res.value
    // const list: any = urlsList[type] as any
    // list.push(url)
    // }
    const userHandleSuccess = (res: any) => {
      // successUpload('caseUser', res)
      const url = res && res.value
      urlsList.caseUser.push(url)
    }
    //手持身份证成功的上传回调
    const handleSuccesse = (res: any) => {
      // successUpload('caseUser', res)

      const url = res && res.value
      if (url != '') {
        // proxy.proxy.$refs.ruleFormRef.clearValidate('identityPhotoUrl')
        urlsList.identityPhotoUrl.push(url)
      }
    }
    const userBackHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.identityPhotoBackUrl.push(url)
    }
    const legalIdentityHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.legalIdentity.push(url)
    }
    const legalWorkIdentityHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.legalWorkIdentity.push(url)
    }
    const hirePaperworkUrlHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.hirePaperworkUrl.push(url)
    }
    const delegationUrlHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.delegationUrl.push(url)
    }

    const agentUserHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.agentUser.push(url)
    }
    const agentUserBackHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.agentUserBack.push(url)
    }
    const agentProxyHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.agentProxy.push(url)
    }
    const agentProxyBackHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.proxyIdentityPhotoBackUrl.push(url)
    }
    const agentTrustHandleSuccess = (res: any) => {
      console.log(
        '🚀 ~ file: UserUpload.vue:457 ~ agentTrustHandleSuccess ~ res:',
        res
      )
      const url = res && res.value
      urlsList.agentTrust.push(url)
    }
    const agentOtherHandleSuccess = (res: any) => {
      const url = res && res.value
      urlsList.agentOther.push(url)
    }
    const userUploadFun = async () => {
      const params: any = {
        identityPhotoUrl: '',
        identityPhotoBackUrl: '',
        proxyIdentityPhotoUrl: '',
        proxyIdentityPhotoBackUrl: '',
        legalIdentity: '',
        legalWorkIdentity: '',
        hirePaperworkUrl: '',
        delegationUrl: '',
        agentUser: '',
        agentProxy: '',
        agentTrust: '',
        agentOther: '',
        institutionId: route.query.institutionId,
        userType: props.userType,
        proxyType: props.proxyType
      }
      if (props.userType == 1) {
        if (props.proxyType == 1) {
          if (urlsList.caseUser.length < 1) {
            throwCustomFunction('请上传当事人身份证明')
          }
          if(identityType.value ==1){
            if(urlsList.identityPhotoBackUrl.length<1){
            throwCustomFunction('请上传当事人身份证背面')
          }
          }
          if (urlsList.identityPhotoUrl.length < 1) {
              throwCustomFunction('请上传手持证件照')
            }
          params.identityPhotoUrl = urlsList.caseUser.toString()
          params.identityPhotoBackUrl = urlsList.identityPhotoBackUrl.toString()
          // if (institutionId.value == 1425) {
      
            params.identityPhotoBackUrl += ',' + urlsList.identityPhotoUrl
          // }
        }
        if (props.proxyType == 2) {
          if (urlsList.legalIdentity.length < 1) {
            throwCustomFunction('请上传当事人身份证明')
          }
          if (
          identityType.value === 1 &&
          urlsList.identityPhotoBackUrl.length < 1
        ) {
          throwCustomFunction('请上传当事人身份证明背面')
        }
          if (urlsList.legalWorkIdentity.length < 1) {
            throwCustomFunction('请上传营业执照副本')
          }
          if (urlsList.identityPhotoUrl.length < 1) {
              throwCustomFunction('请上传手持证件照')
            }
          params.identityPhotoUrl = urlsList.legalIdentity.toString()
     
          params.identityPhotoBackUrl = urlsList.identityPhotoBackUrl.toString()
          params.identityPhotoBackUrl += ',' + urlsList.identityPhotoUrl
          params.companyCertificateUrl = urlsList.legalWorkIdentity.toString()
          if (institutionId.value == '5' || institutionId.value == '14') {
            params.hirePaperworkUrl = urlsList.hirePaperworkUrl.toString()
            params.delegationUrl = urlsList.delegationUrl.toString()
          }
        }
      }
      if (props.userType == 2) {
        if (urlsList.agentUser.length < 1) {
          throwCustomFunction('请上传当事人身份证明')
        }
        if (
          identityType.value === 1 &&
          urlsList.agentUserBack.length < 1
        ) {
          throwCustomFunction('请上传当事人身份证明背面')
        }
        if (urlsList.agentProxy.length < 1) {
          throwCustomFunction('请上传代理人身份证明')
        }
        if (
          agentIdentityType.value==1 &&
          urlsList.proxyIdentityPhotoBackUrl.length < 1
        ) {
          throwCustomFunction('请上传代理人身份证明背面')
        }
        if (urlsList.agentTrust.length < 1) {
          throwCustomFunction('请上传授权委托书')
        }
        if (props.proxyType!=3&&urlsList.identityPhotoUrl.length < 1) {
              throwCustomFunction('请上传手持证件照')
            }
  
        params.identityPhotoUrl = urlsList.agentUser.toString()
        params.identityPhotoBackUrl = urlsList.agentUserBack.toString()

        params.proxyIdentityPhotoUrl = urlsList.agentProxy.toString()
        params.proxyIdentityPhotoBackUrl =
          urlsList.proxyIdentityPhotoBackUrl.toString()
          if(urlsList.identityPhotoUrl){
            params.proxyIdentityPhotoBackUrl += ',' + urlsList.identityPhotoUrl
        }
        params.delegationUrl = urlsList.agentTrust.toString()
        params.otherUrl = urlsList.agentOther.toString()
      }
      if(props.userType==1){
        params.identityType   =identityType.value
      }else if(props.userType==2){
        params.identityType   =identityType.value
        params.agentIdentityType = agentIdentityType.value
      }
      const urlData = location.hash.substring(
        location.hash.indexOf('/') + 1,
        location.hash.indexOf('?')
      )
      const data: any = await AxiosAdapter.post({
        url:
          urlData == 'tian-qiao-confirm'
            ? '/confirmAddress/addOverlineBridgePicture'
            : urlData == 'tian-he-confirm' ||
              urlData == 'feng-xian-confirm' ||
              urlData == 'bao-shan-confirm'|| urlData == 'tou-tun-he-Confirm'
            ? '/confirmAddress/commonAddPicture'
            : '/confirmAddress/addWestCityPicture',
        data: params
      })
      context.emit('nextuser', data)
    }
    const userHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.caseUser.indexOf(item)
      if (index !== -1) {
        urlsList.caseUser.splice(index, 1)
      }
    }
    const handleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.identityPhotoUrl.indexOf(item)
      if (index !== -1) {
        urlsList.identityPhotoUrl.splice(index, 1)
      }
    }
    const userBackHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.identityPhotoBackUrl.indexOf(item)
      if (index !== -1) {
        urlsList.identityPhotoBackUrl.splice(index, 1)
      }
    }
    const legalIdentityHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.legalIdentity.indexOf(item)
      if (index !== -1) {
        urlsList.legalIdentity.splice(index, 1)
      }
    }
    const legalWorkIdentityHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.legalWorkIdentity.indexOf(item)
      if (index !== -1) {
        urlsList.legalWorkIdentity.splice(index, 1)
      }
    }

    const hirePaperworkUrlHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.hirePaperworkUrl.indexOf(item)
      if (index !== -1) {
        urlsList.hirePaperworkUrl.splice(index, 1)
      }
    }

    const delegationUrlHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.delegationUrl.indexOf(item)
      if (index !== -1) {
        urlsList.delegationUrl.splice(index, 1)
      }
    }
    const agentUserHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.agentUser.indexOf(item)
      if (index !== -1) {
        urlsList.agentUser.splice(index, 1)
      }
    }
    const agentUserBackHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.agentUserBack.indexOf(item)
      if (index !== -1) {
        urlsList.agentUserBack.splice(index, 1)
      }
    }
    const agentProxyBackHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.proxyIdentityPhotoBackUrl.indexOf(item)
      if (index !== -1) {
        urlsList.proxyIdentityPhotoBackUrl.splice(index, 1)
      }
    }
    const agentProxyHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.agentProxy.indexOf(item)
      if (index !== -1) {
        urlsList.agentProxy.splice(index, 1)
      }
    }
    const agentTrustHandleRemove = (file: any) => {
      console.log(
        '🚀 ~ file: UserUpload.vue:658 ~ agentTrustHandleRemove ~ file:',
        file
      )
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.agentTrust.indexOf(item)
      if (index !== -1) {
        urlsList.agentTrust.splice(index, 1)
      }
    }
    const agentOtherHandleRemove = (file: any) => {
      const item = file && file.response ? file.response.value[0] : file.url
      const index = urlsList.agentOther.indexOf(item)
      if (index !== -1) {
        urlsList.agentOther.splice(index, 1)
      }
    }
    const getList = (urls?: any) => {
      if (!urls) return
      const arr: any[] = []
      urls.forEach((current: any) => {
        if (current) {
          arr.push({ url: current })
        }
      })
      return arr
    }
    const getUserList = (urls?: any) => {
      return getList(urls)
    }
    const getUserBackList = (urls?: any) => {
      return getList(urls)
    }
    const getProxyList = (urls?: any) => {
      return getList(urls)
    }
    const getProxyBackList = (urls?: any) => {
      return getList(urls)
    }
    const getTrustList = (urls?: any) => {
      return getList(urls)
    }
    const getOtherList = (urls?: any) => {
      return getList(urls)
    }

    const beforeUpload = async (file: any) => {
      return new Promise((resolve) => {
        const data = GetImageConversion(file)
        return resolve(data)
      })
    }

    const handlePictureCardPreview = async (file: any) => {
      dialogImageUrl.value = file.url
      dialogVisible.value = true
    }

    const isHide = (arr: [], count: any) => {
      const bool = arr.length === count
      return bool
    }

    return {
      getUserList,
      getProxyList,
      getTrustList,
      getOtherList,
      dialogImageUrl,
      dialogVisible,
      getUserBackList,
      getProxyBackList,
      institutionId,
      legalIdentityHandleRemove,
      legalWorkIdentityHandleRemove,
      hirePaperworkUrlHandleRemove,
      delegationUrlHandleRemove,
      agentUserHandleRemove,
      handleRemove,
      getList,
      handlePictureCardPreview,
      agentTrustHandleRemove,
      agentOtherHandleRemove,
      userUploadFun,
      agentProxyHandleRemove,
      agentProxyHandleSuccess,
      acceptImageUrl,
      legalIdentityHandleSuccess,
      legalWorkIdentityHandleSuccess,
      hirePaperworkUrlHandleSuccess,
      delegationUrlHandleSuccess,
      agentUserHandleSuccess,
      agentTrustHandleSuccess,
      agentOtherHandleSuccess,
      selectOptions,
      colNum,
      userHandleSuccess,
      handleSuccesse,
      userHandleRemove,
      urlsList,
      getUrl,
      beforeUpload,
      identityType,
      changeIdentityType,
      userBackHandleSuccess,
      userBackHandleRemove,
      proxy,
      changeProxyIdentityType,
      proxyIdentityType,
      agentIdentityType,
      changeAgentIdentityType,
      agentProxyBackHandleSuccess,
      agentProxyBackHandleRemove,
      agentUserBackHandleSuccess,
      agentUserBackHandleRemove,
      isHide
    }
  }
}
